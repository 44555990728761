@import 'styles/includes';

.TotalPie {
    $root: &;
    max-width: 123px;
    position: relative;
    background-color: $colorLichen;
    border-radius: 100vmax;
    background: radial-gradient(
        circle at center,
        transparent 63%,
        $colorLichen 64%
    );
    grid-area: b;
    justify-self: end;

    @include media(m) {
        margin-left: 40px;
        max-width: 135px;
    }

    @include media(l) {
        margin-left: 60px;
    }

    &__Tooltip {
        background-color: #fff;
        position: absolute;
        width: 320px;
        border: 1px solid $colorBlue;
        padding: 24px;
        border-radius: 8px;
        font-size: 1.4rem;
        line-height: 1.6;
        color: $colorBlue;
        left: 0;
        top: 0;
        transform: translateX(calc(-100% - 24px));
        text-wrap: pretty;
        display: none;
        pointer-events: none;

        @include media(ml) {
            display: block;
            opacity: 0;
            transition: opacity 300ms;

            #{$root}:hover & {
                opacity: 1;
            }
        }

        &::before {
            content: '';
            background-color: #fff;
            position: absolute;
            right: 0;
            display: inline-block;
            width: 18px;
            height: 18px;
            transform: rotate(45deg) translateX(calc(50% + 5px));
            border-top: 1px solid $colorBlue;
            border-right: 1px solid $colorBlue;
            z-index: -1;
        }
    }
}
