@import 'styles/includes';

.Searchbar {
    margin: 0;
    width: 81px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: width 800ms $ease;

    &:hover,
    &--Focused {
        width: 164px;

        .Searchbar__Input {
            border: 1px solid $colorBlue;

            &::placeholder {
                color: $colorStone;
                text-align: left;
            }
        }
    }

    &--Large {
        width: 102px;
    }

    &__Input {
        margin: 0;
        padding: 12px 34px 12px 16px;
        width: 100%;
        font-family: $fontBody;
        font-size: 1.4rem;
        letter-spacing: 0.1rem;
        color: $colorContrast;
        border-radius: 70px;
        border: 1px solid transparent;
        transition:
            border 800ms $ease,
            border-radius 800ms $ease,
            background-position 800ms $ease;
        text-indent: 0;

        &::placeholder {
            font-size: 1.4rem;
            letter-spacing: 0.1rem;
            color: $colorContrast;
            transition: color 800ms $ease;
        }

        &:focus {
            background-color: $colorLichen;
        }
    }

    &__Submit {
        padding: 0;
        width: 14px !important;
        height: 15px !important;
        background-image: url('/img/search.svg');
        background-size: 14px 15px;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent !important;
        border: 0;
        border-radius: 0 !important;
        position: absolute;
        top: 13.5px;
        right: 17px;
    }

    &__DeleteButton {
        border: 0;
        background-color: transparent;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 15px;
        right: 14px;
        z-index: 1;
        transition: opacity $transition;

        &--Visible {
            opacity: 1;
            visibility: visible;
        }
    }
}
