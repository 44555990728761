$breakpoints: (
    xs: 378px,
    s: 532px,
    m: 768px,
    ml: 1024px,
    ls: 1200px,
    l: 1440px,
    xl: 1680px,
    xxl: 1930px,
);

$maxwidths: (
    xs: 345px,
    s: 500px,
    m: 720px,
    ml: 920px,
    l: 1260px,
    xl: 1440px,
);

$spacingXs: 4px;
$spacingS: 8px;
$spacingM: 16px;
$spacingL: 32px;

$colorContrast: #003742;
$colorBlue: #005b75;
$colorFocusBlue: #4c8c9e;
$colorLightBlue: #759dad;
$colorWater: #cfeff3;
$colorLichen: #f1f5f3;
$colorForest: #bcdcbb;
$colorYellow: #feca01;
$colorVanilla: #fffbdf;
$colorStone: #c6c6c6;
$colorError: #e13200;
$colorGradient: linear-gradient(
    45.79deg,
    #fff8c4 2.97%,
    $colorLichen 45.52%,
    #e2ebe6 94.77%
);

$colorWhite: #ffffff;
$colorGrey-10: #f5f5f5;
$colorGrey-20: #eeeeee;
$colorGrey-30: #e0e0e0;
$colorGrey-40: #bdbdbd;
$colorGrey-50: #9e9e9e;
$colorGrey-60: #757575;
$colorGrey-70: #616161;
$colorGrey-80: #424242;
$colorGrey-90: #212121;
$colorBlack: #000000;

$fontRegular: 'MuseoSans Regular', sans-serif;
$fontBold: 'MuseoSans Bold', sans-serif;
$fontBlack: 'MuseoSans Black', sans-serif;
$fontBody: 'Inter', sans-serif;

// Transitions
$duration: 0.3s;
$ease: ease-in-out;
$transition: $duration $ease;
$transitionDelayFast: 0.1s;
$transitionDelayMedium: 0.3s;
$transitionDelaySlow: 0.5s;
