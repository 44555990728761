@import 'styles/includes';

.ArticlePage {
    &__Main {
        margin-bottom: 3rem;
        
        @include media(ml) {
            margin-bottom: 6rem;
        }
    }

    &__Article {
        @extend %container;
        margin-top: 3rem;
        
        @include media(ml) {
            margin-top: 6rem;
            display: grid;
            grid-template-columns: 1fr 262px;
            grid-gap: 24px;
        }

        &__Sidebar {
            width: 100%;
        }
    }

    &__Text {
        max-width: 84rem;
        color: $colorContrast;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $colorBlue;
        }

        p {
            margin-bottom: 2rem;
        }

        ul,
        ol {
            margin-bottom: 2rem;
        }

        img {
            margin-bottom: 2rem;
            max-width: 100%;
        }
    }
}
