@import 'styles/includes';

.Step2 {
    &__Back {
        @extend %step-back;
    }

    &__Header {
        margin: 0 0 2.4rem 0;
        display: flex;
        gap: 2.4rem;
        justify-content: space-between;
        align-items: flex-start;

        &--MarginTop {
            margin-top: 2.4rem;
        }
    }

    &__RichText {
        &--Half {
            @include media(ml) {
                max-width: 50%;
            }
        }
    }

    &__Info {
        color: $colorContrast;
        line-height: 1.5;
        margin-top: 2rem;
    }

    &__Logo {
        display: none;

        @include media(m) {
            width: 100%;
            max-width: 152px;
            height: 45px;
            display: block;
            background-image: url('/img/logo-jamtkraft.svg');
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
        }
    }

    &__Form {
        @include media(ml) {
            max-width: 50%;
        }
    }

    &__Grid {
        display: grid;
        gap: 2.4rem;

        @include media(m) {
            grid-template-columns: 3fr 2fr;
            align-items: start;
        }
    }

    &__Row {
        margin-top: 2.4rem;

        @include media(m) {
            display: flex;
            gap: 2.4rem;
        }
    }

    &__Button {
        @include media(m) {
            margin-top: 1.2rem;
        }
    }

    &__Tooltip {
        margin: 20px 0;

        @include media(m) {
            width: calc(50% - 12px);
        }
    }

    &__DialogButton {
        @extend %small;
        padding: 0;
        color: $colorBlue;
        text-decoration: underline;
        border: 0;
        background-color: transparent;
    }

    &__Address {
        margin: 20px 0;
        padding: 22px 20px;
        width: 100%;
        border-radius: 8px;
        background-color: $colorLichen;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &__AddressLabel {
        @extend %label;
        display: block;
        width: 100%;
    }

    &__List {
        @extend %small;
        width: 50%;
        color: $colorBlue;

        @include media(m) {
            margin-top: 0.8rem;
            margin-bottom: 0;
        }
    }

    &__Error {
        @extend %help-text;
        color: $colorError;
    }

    &__Housing {
        margin: 2.4rem 0 0 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2.4rem;
        width: 100%;
    }

    &__ApartmentTooltip {
        margin-top: 2.4rem;
        display: grid;
        gap: 2.4rem;
    }

    &__EditButton {
        border: 1px solid $colorBlue;
        border-radius: 30px;
        color: #fff;
        font-family: $fontBody;
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: auto;
        padding: 0 16px;
        background-color: $colorBlue;
        transition: all 0.3s;

        &:hover {
            color: $colorBlue;
            background-color: #fff;
        }
    }
}

.SelectButton {
    &__Button {
        padding: 14px;
        width: 100%;
        font-size: 1.2rem;
        font-family: $fontBold;
        font-weight: 700;
        letter-spacing: 0.02rem;
        color: $colorBlue;
        word-break: break-word;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: $colorWhite;
        border: 1px solid $colorBlue;
        border-radius: 0.8rem;
        box-shadow: 0 0 0 0 $colorYellow;
        transition:
            box-shadow $transition,
            background-color $transition,
            color $transition;

        @include media(m) {
            padding: 16px 14px;
            font-size: 1.6rem;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        &:hover {
            color: $colorWhite;
            background-color: $colorBlue;
            box-shadow: 9px -9px 0px 0px $colorYellow;
        }

        &--Active {
            color: $colorWhite;
            background-color: $colorBlue;
            box-shadow: 9px -9px 0px 0px $colorYellow;
        }
    }

    &__Icon {
        margin: 0 0 10px 0;
        width: 32px;
        height: 38px;
        flex-shrink: 0;

        @include media(m) {
            margin: 0 16px 0 0;
        }
    }
}
