@import 'styles/includes';

.AccountForm {
    $root: &;

    @extend %container;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media(m) {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }

    &__Success {
        @extend %p;
        margin: 0 0 2rem;
        padding: 1.5rem;
        max-width: 26.2rem;
        color: $colorContrast;
        background-color: $colorLichen;
    }

    &__Form {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2.4rem;

        @include media(m) {
            max-width: 54.8rem;
            grid-template-columns: repeat(2, 1fr);
            gap: 2.8rem 2.4rem;
        }
    }

    &__Button {
        margin-top: 1.4rem;

        @include media(m) {
            margin-top: 2.8rem;
        }
    }

    &__Spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.2rem;
        aspect-ratio: 920 / 270;
        color: $colorBlue;
    }
}
