@import 'styles/includes';

.SunparkPage {
    &__Content {
        padding: 54px 0;
        position: relative;
        z-index: 0;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            max-height: 70%;
            background: linear-gradient(
                357.01deg,
                rgba(255, 248, 196, 0.01) 8.47%,
                rgba(253, 194, 1, 0.35) 32.04%,
                #e2ebe6 73.46%
            );
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            left: 0;
        }
    }
}
