@import 'styles/includes';

.AccordionAgreementList {
    $root: &;

    @extend %container;
    margin-top: 3rem;
    margin-bottom: 6rem;

    &__Grid {
        display: grid;
        gap: 2rem;
    }

    &__Spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.2rem;
        aspect-ratio: 920 / 270;
        color: $colorBlue;
    }
}
