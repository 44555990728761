@import 'styles/includes';

.FormMessage {
    margin: 0 auto;
    max-width: 540px;

    &__Icon {
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $colorBlue;

        @include media(m) {
            margin-bottom: 3.4rem;
        }
    }
}
