@import 'styles/includes';

.AccountContactForm {
    $root: &;
    @extend %container;

    &__Form {
        display: grid;
        gap: 2rem;
    }

    &__Grid {
        display: grid;
        gap: 2.4rem;

        @include media(m) {
            grid-template-columns: 262px 262px 262px 161px;
        }
    }

    &__RemoveButton {
        font-family: $fontBody;
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: $colorContrast;
        background-color: transparent;
        border: 0;
    }

    &__ButtonContainer {
        display: flex;
        gap: 2rem;
    }
}
