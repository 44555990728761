@import 'styles/includes';

.SignupElectricityForm {
    @extend %container;
    padding-top: 20px;
    padding-bottom: 144px;


    &__Spinner {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 127px);
        animation: fadeInMessage 2s forwards;

        @include media(m) {
            height: calc(100vh - 200px);
        }
    }
}

@keyframes fadeInMessage {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
