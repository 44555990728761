@import 'styles/includes';

.Header {
    $root: &;

    background-color: $colorWhite;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    transition: ease-out 0.4s transform;

    &--Hidden {
        transform: translateY(-100%);
    }

    &__Top {
        display: none;

        @include media(ml) {
            display: block;
            border-bottom: 1px solid $colorLichen;
            font-size: 14px;
            color: $colorContrast;
        }
    }

    &__Bottom {
        position: relative;
        z-index: 999;
        background-color: $colorWhite;
    }

    &__Inner {
        @extend %nav-container;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__Logo {
        margin: 1.2rem 0 1.2rem 0;
        width: 100%;
        min-width: 152px;
        max-width: 152px;
        height: 45px;
        display: block;
        background-image: url('/img/logo-jamtkraft.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__Left {
        display: flex;
        gap: 3.4rem;

        &:first-child {
            margin-left: 1.9rem;
        }
    }

    &__Right {
        display: flex;
    }

    &__Tab {
        padding: 1.6rem 0;
        font-size: 1.4rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: $colorContrast;
        background-color: transparent;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        position: relative;

        &:hover {
            &::after {
                width: 100%;
                background-color: $colorYellow;
            }
        }

        &::after {
            content: '';
            width: 0;
            height: 4px;
            background-color: transparent;
            position: absolute;
            left: 0;
            bottom: -1px;
            transition: width $transition;
        }

        &--Active {
            &::after {
                transition: none;
                width: 100%;
                background-color: $colorYellow;
            }
        }
    }

    &__Icons {
        display: flex;
        justify-content: center;
        align-items: baseline;
        position: relative;
        gap: 2rem;

        @include media(ml) {
            display: none;
        }
    }

    &__MyPages {
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 0.7rem;
        color: $colorBlue;
    }

    &__SearchButton {
        padding: 0;
        border: 0;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 0.6rem;
    }

    &__SearchText,
    &__ProfileText {
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        color: $colorBlue;
        display: inline-block;
    }

    &__Toggle {
        padding: 0;
        background-color: transparent;
        border: 0;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        align-items: center;
        position: relative;
        top: -0.2rem;
    }

    &__Hamburger {
        position: relative;
        display: block;
        width: 2.5rem;
        height: 1.8rem;
    }

    &__Line {
        width: 2.5rem;
        height: 0.2rem;
        background-color: $colorBlue;
        border-radius: 1rem;
        display: block;
        position: absolute;
        left: 0;
        transition:
            background-color $transition,
            transform $transition,
            opacity $transition;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 0.8rem;
            background-color: $colorYellow;
        }

        &:nth-child(3) {
            bottom: 0;
        }

        #{$root}__Toggle--Active & {
            &:nth-child(1) {
                transform: translateY(0.6rem) rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: translateY(-1rem) rotate(-45deg);
                background-color: $colorYellow;
            }
        }
    }

    &__ToggleText {
        width: 3.3rem;
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        color: $colorBlue;
    }

    &__ButtonContainer {
        display: none;

        @include media(ml) {
            display: block;
        }
    }

    &__Navigation {
        display: none;

        @include media(ml) {
            display: block;
        }
    }

    &__NavigationList {
        @include media(ml) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &__NavigationItem {
        color: $colorBlue;
        margin: 0 15px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__NavigationLink {
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 3.3rem;
        padding-bottom: 3.3rem;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 600;
        color: $colorBlue;
        position: relative;
        background-color: transparent;
        display: inline;
        border: 0;
        border-radius: 0;

        &::after {
            content: '';
            height: 4px;
            width: 0;
            background-color: transparent;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: $transition;

            #{$root}__NavigationItem--Active & {
                width: 100%;
                background-color: $colorYellow;
            }
        }

        &:focus-visible,
        &:hover {
            &::after {
                background-color: $colorYellow;
                width: 100%;
            }
        }
    }

    &__Menu {
        display: none;
        background-color: $colorWhite;
        position: absolute;
        top: 84px;
        left: 0;
        right: 0;
        z-index: 99;
        border-bottom: 1px solid $colorBlue;

        #{$root}__NavigationItem--Open & {
            display: block;
        }
    }

    &__MenuInner {
        @extend %nav-container;
        padding-top: 30px;
        padding-bottom: 48px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @include media(ml) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    &__ListCard {
        grid-column-start: 4;

        @include media(ml) {
            grid-column-start: 5;
        }
    }

    &__ListItem {
        &:first-child {
            @include media(ml) {
                grid-column-start: 2;
            }
        }
    }

    &__ListLink {
        margin: 0 0 24px 0;
        display: inline-block;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 0.04rem;
        text-transform: uppercase;
        line-height: 2.2rem;

        &::after {
            content: '';
            margin-left: 10px;
            background-image: url('/img/arrow.svg');
            background-repeat: no-repeat;
            background-size: 14px 10px;
            width: 14px;
            height: 10px;
            color: $colorBlue;
            display: inline-block;
            transition: transform $transition;
        }

        &:hover {
            text-decoration: underline;
            text-decoration-thickness: 0.2rem;
            text-underline-offset: 0.4rem;

            &::after {
                transform: translateX(10px);
            }
        }
    }

    &__SubmenuItem {
        color: $colorBlue;
        font-family: $fontRegular;
        font-weight: 400;

        &:not(:last-child) {
            margin: 0 0 22px 0;
        }
    }

    &__SubmenuLink {
        &:hover {
            text-decoration: underline;
            text-decoration-thickness: 0.1rem;
            text-underline-offset: 0.4rem;
        }
    }
}
