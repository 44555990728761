@import 'styles/includes';

.SearchResult {
    margin: 4rem 0;
    &__Container {
        @extend %container;
    }

    &__Grid {
        @include media(m) {
            display: grid;
            grid-template-columns: 1fr 262px;
            gap: 2.3rem;
            position: relative;
        }
    }

    &__Title {
        @extend %h3;
        color: $colorBlue;
    }

    &__NoResult {
        @extend %p;
        margin-top: 24px;
        color: $colorContrast;
    }

    &__Search {
        position: relative;
        max-width: 834px;
    }

    &__Input {
        margin-bottom: 4rem;
        padding: 1.6rem 5.2rem 1.5rem 5rem;
        width: 100%;
        font-size: 1.6rem;
        color: $colorContrast;
        border-radius: 7rem;
        border: 1px solid $colorBlue;
        background-image: url('/img/search.svg');
        background-size: 14px 15px;
        background-repeat: no-repeat;
        background-position: center left 2.5rem;

        @include media(m) {
            padding: 2.3rem 6.2rem 2.2rem 6rem;
            background-position: center left 3.5rem;
        }

        &:focus {
            background-color: $colorLichen;
        }
    }

    &__DeleteButton {
        border: 0;
        background-color: transparent;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 2.2rem;
        right: 3rem;
        z-index: 1;
        transition: opacity $transition;

        @include media(m) {
            top: 2.85rem;
            right: 4rem;
        }

        &--Visible {
            opacity: 1;
            visibility: visible;
        }
    }

    &__ButtonContainer {
        display: flex;
        flex-wrap: wrap;

        @include media(m) {
            display: block;
            order: 1;
            position: sticky;
            top: 146px;
            align-self: flex-start;
        }
    }

    &__Card {
        margin-top: 26px;

        &--ShowDesktop {
            display: none;

            @include media(m) {
                display: block;
            }
        }

        &--HideDesktop {
            @include media(m) {
                display: none;
            }
        }
    }
    &__Paginator {
        margin: 40px 0 0 0;
        text-align: center;
    }

    &__PageNumber {
        margin: 0 16px;
        font-weight: 400;
        color: $colorContrast;

        &:hover {
            cursor: pointer;
        }
    }

    &__CurrentPage {
        width: 27px;
        height: 27px;
        background-color: $colorBlue;
        border-radius: 18px;
        margin: 2px;
        font-family: $fontBold;
        font-weight: 600;
        color: $colorWhite;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__Ellipsis {
        margin: 0 16px;
        font-weight: 400;
        color: $colorContrast;
    }
}

.Result {
    &__Title {
        @extend %h4;
        margin: 3rem 0 1rem 0;
        color: $colorBlue;
    }

    &__Link {
        margin: 1rem 0;
        font-size: 1.4rem;
        line-height: 1.71;
        text-decoration: underline;
        color: $colorContrast;
        word-break: break-all;
    }

    &__Text {
        margin: 1rem 0 0.5rem 0;
        font-size: 1.6rem;
        line-height: 1.63;
        color: $colorContrast;
    }

    &__Date {
        font-size: 1.4rem;
        line-height: 1.3;
        color: $colorContrast;
    }
}
