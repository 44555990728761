@import 'styles/includes';

.CardCtaSmallList {
    background: $colorGradient;

    &--Gradient {
        background: $colorGradient;
    }

    &--Transparent {
        background: transparent;
    }

    &__Container {
        @extend %container;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }

    &__Title {
        @extend %h4;
        color: $colorBlue;
        margin-bottom: 16px;

        @include media(ml) {
            margin-bottom: 38px;
            text-align: center;
        }
    }

    &__List {
        @include media(ml) {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
        }
    }

    &__Item {
        margin-bottom: 16px;

        @include media(ml) {
            width: calc(50% - 24px);
            margin-bottom: 0;
        }
    }
}
