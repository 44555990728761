@import 'styles/includes';

.SignupFork {
    @extend %container;
    padding-top: 20px;
    padding-bottom: 144px;

    &__Back {
        @extend %step-back;
    }

    &__Header {
        margin: 0 0 3.2rem 0;

        @include media(m) {
            width: 100%;
            max-width: 598px;
        }
    }

    &__Bottom {
        margin-top: 4rem;
    }

    &__Message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 127px);
        animation: fadeInMessage 2s forwards;

        @include media(m) {
            height: calc(100vh - 230px);
        }
    }
}

@keyframes fadeInMessage {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
