@import 'styles/includes';

.Step1 {
    $root: &;
    @extend %container;
    padding-top: 30px;

    &__Back {
        @extend %step-back;
        margin-bottom: 3rem;
    }

    &__Form {
        display: flex;
        flex-direction: column;
        gap: 4rem;
    }

    &__Text {
        margin-top: 0.6rem 0;
    }

    &__Title {
        @extend %h3;
        color: $colorBlue;
        margin-bottom: 0.8rem;
    }

    &__Preamble {
        @extend %p;
        color: $colorContrast;
        max-width: auto;

        @include media(m) {
            max-width: calc(50% - 1rem);
        }
    }

    &__HourlyMeasured {
        max-width: auto;

        @include media(m) {
            max-width: calc(50% - 1rem);
        }
    }

    &__HourlyMeasuredTitle {
        @extend %h4;
        color: $colorBlue;
        margin-bottom: 2rem;
    }

    &__HourlyMeasuredPreamble {
        @extend %small;
        color: $colorContrast;
    }

    &__Asterisk {
        @extend %p;
        color: $colorContrast;

        @include media(m) {
            max-width: calc(50% - 1rem);
        }
    }

    &__StartDatePicker {
        max-width: 26rem;
        padding-bottom: 2rem;
    }

    &__StartDateTitle {
        @extend %h3;
        color: $colorBlue;
        margin-bottom: 1rem;
    }

    &__StartDatePreamble {
        @extend %small;
        color: $colorContrast;
        margin-bottom: 2rem;

        max-width: auto;

        @include media(m) {
            max-width: calc(50% - 1rem);
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__Spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.2rem;
        aspect-ratio: 920 / 270;
        color: $colorBlue;
    }

    &__Input {
        @extend %p;
        width: 100%;
        margin-top: 8px;
        padding: 16px 20px;
        letter-spacing: 1px;
        color: $colorBlue;
        border-radius: 8px;
        border: 1px solid $colorBlue;

        @include media-max(s) {
            font-size: 1.6rem;
        }

        .FormDatePickerField--Focused & {
            background-color: $colorLichen;
        }
    }
}
