@import 'styles/includes';

.AccordionInvoice {
    $root: &;

    background-color: $colorWhite;
    border: 0.05rem $colorBlue solid;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 0px 0.15rem rgba(0, 91, 117, 0);
    transition: box-shadow $transition;

    &:hover {
        box-shadow: 0px 0px 0px 0.15rem rgba(0, 91, 117, 1);
    }

    &__Button {
        padding: 1.6rem 1.6rem;
        width: 100%;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.6rem;
        letter-spacing: 0.01rem;
        color: $colorBlue;
        background-color: $colorLichen;
        border: 0;
        border-radius: 0.8rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr) auto;
        align-items: center;
        gap: 2rem;

        @include media(m) {
            padding: 2.3rem 1.6rem;
            grid-template-columns: auto 170px 1fr auto auto;
        }

        #{$root}--Expanded & {
            border-radius: 0.8rem 0.8rem 0 0;
        }
    }

    &__Status {
        min-width: 10rem;
        display: flex;
        gap: 2.4rem;

        @include media(m) {
            margin-right: 11rem;
        }
    }

    &__Expires {
        display: none;

        @include media(m) {
            display: block;
            text-align: left;
        }
    }

    &__InvoiceTypeContainer {
        display: none;

        @include media(m) {
            margin-left: 3.8rem;
            text-align: left;
            display: inline-block;
        }
    }

    &__InvoiceType {
        margin: 0 0 0 1.5rem;
        font-family: $fontBody;
        font-weight: 400;
    }

    &__Amount {
        text-align: right;
    }

    &__Icon {
        #{$root}--Expanded & {
            transform: rotate(180deg);
        }
    }

    &__Panel {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows $transition;

        #{$root}--Expanded & {
            grid-template-rows: 1fr;
        }
    }

    &__Inner {
        overflow: hidden;
        visibility: hidden;
        transition: visibility $transition;

        #{$root}--Expanded & {
            visibility: visible;
        }
    }

    &__Content {
        padding: 1.6rem 1.6rem 2.4rem 1.6rem;
    }

    &__Table {
        font-family: $fontBody;
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: 400;
        color: $colorContrast;
        display: grid;
        gap: 0.3rem;

        @include media(s) {
            grid-template-columns: repeat(2, fit-content(40rem));
            column-gap: 2rem;
            row-gap: 0.8rem;
        }

        @include media(m) {
            font-size: 1.6rem;
            line-height: 2.6rem;
        }
    }

    &__CellLeft {
        font-family: $fontBold;
        font-weight: 600;
        letter-spacing: 0.01rem;
        color: $colorBlue;
    }

    &__CellRight {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include media(m) {
            justify-content: space-between;
        }
    }

    &__CopyContainer {
        display: inline-flex;
        position: relative;
    }

    &__Copy {
        padding: 0.5rem;
        border: 0;
        background-color: transparent;
        color: $colorBlue;
    }

    &__Copied {
        @extend %small;
        padding: 0.2rem 0.8rem;
        display: inline-flex;
        font-size: 1.2rem;
        color: $colorContrast;
        background-color: $colorLichen;
        border-radius: 0.8rem;
        opacity: 1;
        transition: opacity $transition;
        position: absolute;
        left: calc(100% + 1rem);
        bottom: -0.3rem;

        &::before {
            content: '';
            position: absolute;
            bottom: 1rem;
            left: -1rem;
            border-width: 0.5rem;
            border-style: solid;
            border-color: transparent $colorLichen transparent transparent;
        }
    }

    &__Tooltip {
        margin: 1.2rem 0 0;
        max-width: 58rem;
    }

    &__PriceSpecification {
        margin: 24px 0 0;
        max-width: 440px;
    }

    &__ButtonContainer {
        padding: 1.5rem 1.6rem;
        background-color: $colorLichen;
        border-radius: 0 0 0.8rem 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media(m) {
            justify-content: flex-end;
            padding: 1.5rem 3rem;
        }
    }
}
