@import 'styles/includes';

.RichText {
    @extend %rich-text;
    color: $colorContrast;

    h2 {
        @extend %h3;
    }

    h3 {
        @extend %h4;
    }

    h4 {
        @extend %h5;
    }

    h5 {
        @extend %h5;
    }

    h6 {
        @extend %h5;
    }
}
