@import 'styles/includes';

.FilterButton {
    margin: 0 10px 12px 0;
    padding: 12px 20px;
    font-family: $fontBlack;
    font-size: 1.6rem;
    font-weight: 900;
    letter-spacing: 0.02rem;
    line-height: 2.4rem;
    text-align: left;
    color: $colorBlue;
    border: 1px solid $colorBlue;
    border-radius: 8px;
    background-color: $colorLichen;
    display: inline-block;
    box-shadow: 0 0 0 0 $colorYellow;
    transition: box-shadow $transition;

    @include media(m) {
        width: 100%;
        margin: 0 0 12px 0;
    }

    &--Active {
        background-color: $colorBlue;
        color: $colorWhite;
    }

    &:hover {
        box-shadow: 5px -5px 0px 0px $colorYellow;
    }

    &:last-of-type {
        @include media(m) {
            margin: 0;
        }
    }
}
