@import 'styles/includes';

.CardJob {
    padding: 20px;
    background-color: $colorLichen;
    border: 1px solid $colorBlue;
    border-radius: 8px;
    box-shadow: 0 0 0 0 $colorYellow;
    transition: box-shadow $transition;

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    &__Grid {
        @include media(ml) {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 48px;
            align-items: center;
            justify-content: center;
        }
    }

    &--Hover {
        box-shadow: 10px -10px 0px 0px $colorYellow;
    }

    &__Header {
        margin: 0 0 1.7rem 0;

        @include media(ml) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-start;
            gap: 2.4rem;
        }
    }

    &__Title {
        font-family: $fontBlack;
        font-size: 1.6rem;
        font-weight: 900;
        letter-spacing: 0.02rem;
        line-height: 2.4rem;
        color: $colorBlue;
    }

    &__Tag {
        margin-bottom: 1rem;
        padding: 0.5rem 2.2rem 0.5rem 1rem;
        background-color: $colorYellow;
        border-radius: 6rem;
        border-color: transparent;
        display: inline-block;
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.031rem;
        line-height: 1.6rem;
        text-transform: uppercase;
        white-space: nowrap;
        transition: $transition;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        @include media(ml) {
            margin: 0;
        }

        &::before {
            content: '';
            margin-right: 14px;
            min-width: 10px;
            width: 10px;
            height: 10px;
            background-color: $colorLichen;
            border-radius: 100%;
            display: inline-block;
            position: relative;
        }
    }

    &__Text {
        @extend %p;
        color: $colorContrast;
    }

    &__Deadline {
        @extend %small;
        margin-top: 10px;
        color: $colorContrast;
        text-transform: uppercase;
        display: inline-block;
    }

    &__Button {
        margin-top: 15px;
        padding: 16px 40px 14px;
        display: inline-block;
        font-family: $fontBold;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 22px;
        text-transform: uppercase;
        white-space: nowrap;
        color: $colorBlue;
        background-color: $colorWhite;
        border-radius: 6rem;
        border: 2px solid $colorBlue;
        transition: $transition;

        @include media(ml) {
            margin-top: 0;
        }

        &:hover {
            background-color: $colorBlue;
            color: $colorWhite;
        }
    }
}
