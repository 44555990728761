@import 'styles/includes';

.Summary {
    @extend %container;
    padding-top: 30px;

    &__Back {
        @extend %step-back;
        margin-bottom: 3rem;
    }

    &__Container {
        margin: 0 auto;
        max-width: 510px;
    }

    &__Icon {
        margin: 0 auto 30px auto;
        width: 100px;
        height: 120px;
        color: $colorBlue;
    }

    &__Title {
        @extend %h3;
        margin: 0 0 30px;
        text-align: center;
        color: $colorBlue;
    }

    &__List {
        @extend %ul;
        margin: 0 0 16px;
        display: grid;
        align-items: center;
        justify-content: center;
    }

    &__Text {
        @extend %rich-text;
        margin: 0 auto 30px auto;
        text-align: center;
        color: $colorContrast;
        line-height: 26px;
    }

    &__Logo {
        margin: 0 auto 60px auto;
        width: 100%;
        max-width: 343px;
        height: 42px;
        display: block;
        background-image: url('/img/logo-jamtkraftxtibber.svg');
        background-size: contain;
        background-repeat: no-repeat;
        flex-shrink: 0;

        @include media(m) {
            max-width: 462px;
            height: 60px;
        }
    }

    &__Spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.2rem;
        aspect-ratio: 920 / 270;
        color: $colorBlue;
    }
}
