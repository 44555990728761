@import 'styles/includes';

.InlineRichText {
    @extend %container;

    &__Alignment {
        max-width: 834px;

        &--Left {
            margin-right: auto;
        }

        &--Center {
            margin: 0 auto;
        }

        &--Right {
            margin-left: auto;
        }
    }
}
