@import 'styles/includes';

.FacilitySelect {
    $root: &;

    margin-top: 2.4rem;
    padding-top: 8px;
    position: relative;

    @include media(ml) {
        margin-top: 0;
    }

    &__Label {
        @extend %label;
        margin: 0 -4px;
        padding: 0 4px 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 20px;
        pointer-events: none;
    }

    &__Line {
        width: 12rem;
        height: 0.4rem;
        background-color: $colorWhite;
        position: absolute;
        top: 1rem;
        left: 1.6rem;
        transform: translateY(-50%);
        pointer-events: none;
    }

    &__Input {
        @extend %p;
        margin: 0.1rem 0 0;
        padding: 1.6rem 4rem 1.6rem 2rem;
        width: 100%;
        min-width: 250px;
        letter-spacing: 0.1rem;
        color: $colorBlue;
        border-radius: 0.8rem;
        border: 0.5px solid $colorBlue;
        background-color: $colorWhite;
        background-image: url('/img/chevron.svg');
        background-position: center right 20px;
        background-repeat: no-repeat;
        background-size: 12px 7px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        @include media(l) {
            margin-right: 4rem;
        }
    }
}
