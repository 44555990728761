@import 'styles/includes';

.CardElectricyContractList {
    display: grid;
    gap: 2.4rem;

    @include media(m) {
        grid-template-columns: repeat(2, 1fr);
    }
}
