@import 'styles/includes';

.MyPagesBusinessInvoicePage {
    $root: &;

    &__Main {
        margin-bottom: 3rem;

        @include media(ml) {
            margin-bottom: 6rem;
        }
    }
}
