@import 'styles/includes';

.ArticleSidebar {
    padding: 2rem;
    border-radius: 8px;
    background-color: $colorLichen;
    word-break: break-word;

    @include media-max(ml) {
        margin: 3.2rem 0 0 0;
    }

    &:not(:last-of-type) {
        margin-bottom: 2.4rem;
    }

    &--Gradient {
        background-image: $colorGradient;
    }

    &__Title {
        @extend %h4;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        color: $colorBlue;
        border-bottom: 1px solid $colorYellow;
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 1.71;
        color: $colorContrast;
        word-break: break-word;

        a {
            border-bottom: 1px;
            border-style: solid;
            border-color: inherit;

            &:hover {
                border-color: transparent;
            }
        }

        b {
            font-family: $fontBold;
            font-weight: 700;
        }

        i {
            font-style: italic;
        }

        img {
            margin-top: 1.6rem;
            width: 100%;

            &:not(:last-of-type) {
                margin-bottom: 1rem;
            }
        }

        p {
            &:not(:last-of-type) {
                margin-bottom: 14px;
            }
        }

        ul {
            @extend %ul;
            margin-top: 1rem;
        }

        div {
            ul {
                list-style: none;

                li {
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        ol {
            @extend %ol;
            margin-top: 1rem;

            li {
                list-style-type: none;
            }
        }
    }

    &__List {
        margin-top: 1rem;
    }

    &__ListItem {
        &:not(:last-of-type) {
            margin-bottom: 1.2rem;
        }
    }

    &__Icon {
        flex-shrink: 0;
        display: inline-block;
        margin-right: 1.4rem;
    }

    &__Link {
        color: $colorBlue;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            .ArticleSidebar__LinkTextInner {
                border-color: transparent;
            }
        }
    }

    &__LinkText {
        font-family: $fontBold;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 2;
        letter-spacing: 0.05rem;
        color: $colorContrast;
    }

    &__LinkTextInner {
        border-bottom: 2px solid $colorContrast;
        transition: border-color $transition;
    }
}
