@import 'styles/includes';

.Tooltip {
    $root: &;
    position: relative;

    &__Button {
        padding: 0;
        font-family: $fontBold;
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 0.05rem;
        text-align: left;
        text-decoration: underline;
        text-transform: uppercase;
        color: $colorBlue;
        background-color: transparent;
        border: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__Icon {
        margin-right: 8px;
    }

    &__Content {
        @extend %small;

        margin: 8px 0 0 0;
        padding: 8px;
        width: 100%;
        max-width: fit-content;
        font-size: 1.2rem;
        color: $colorContrast;
        background-color: $colorLichen;
        border-radius: 8px;
        position: absolute;
        left: 0;
        visibility: hidden;
        z-index: 9;
        box-shadow: 2px 2px 3px 0px rgb(0 91 117 / 20%);

        #{$root}--White & {
            background-color: $colorWhite;
        }

        &::after {
            content: ' ';
            position: absolute;
            bottom: 100%;
            left: 40px;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent $colorLichen transparent;

            #{$root}--White & {
                border-color: transparent transparent $colorWhite transparent;
            }
        }

        a {
            text-decoration: underline;
        }

        p {
            @extend %small;
            font-size: 1.2rem;
            color: $colorContrast;
        }

        #{$root}--IsExpanded & {
            visibility: visible;
        }
    }
}
