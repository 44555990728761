@import 'styles/includes';

.MyPagesLoader {
    $root: &;

    aspect-ratio: 920 / 270;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.2rem;
    color: $colorBlue;

    &__Text {
        @extend %rich-text;
        color: $colorContrast;
    }
}
