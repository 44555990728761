@import 'styles/includes';

.Faq {
    @extend %container;
    margin-top: 60px;
    margin-bottom: 60px;

    &__Grid {
        @include media(m) {
            display: grid;
            grid-template-columns: 1fr 262px;
            gap: 140px;
            position: relative;
        }
    }

    &__ButtonContainer {
        margin-bottom: 20px;
        order: 1;
        display: flex;
        flex-wrap: wrap;

        @include media(m) {
            display: block;
            order: 1;
            align-self: flex-start;
        }
    }

    &__FaqListing {
        @include media(m) {
            order: 0;
        }
    }
}

.FaqBlock {
    &:not(:first-of-type) {
        margin-top: 40px;
    }

    &__Title {
        @extend %h3;
        @extend %curved-border-h3;
        margin-bottom: 10px;
        color: $colorBlue;
        position: relative;
        display: inline-block;
        z-index: 0;

        @include media(m) {
            margin-bottom: 23px;
        }
    }
}
