@import 'styles/includes';

.Icon {
    display: flex;
    align-self: center;
    align-content: center;
    flex-shrink: 0;
    pointer-events: none;

    svg {
        width: 100%;
        height: 100%;
    }

    &--Xsmall {
        svg {
            max-width: 14px;
            max-height: 14px;
        }
    }

    &--Small {
        svg {
            max-width: 15px;
            max-height: 15px;
        }
    }

    &--Medium {
        svg {
            max-width: 18px;
            max-height: 18px;
        }
    }

    &--Large {
        svg {
            max-width: 26px;
            max-height: 26px;
        }
    }

    &--Xlarge {
        svg {
            max-width: 55px;
            max-height: 55px;
        }
    }

    &--Xxlarge {
        svg {
            max-width: 87px;
            max-height: 87px;
        }
    }

    &--Yellow {
        transition: $transition;
        &:hover {
            color: $colorYellow;
        }
    }

    &--Blue {
        color: $colorBlue;
    }

    &--White {
        color: $colorWhite;
    }

    &--Green {
        color: $colorForest;
    }

    &--Red {
        color: $colorError;
    }

    &--Footer {
        margin-right: 1.6rem;
    }

    &--Rotate180 {
        transform: rotate(180deg);
    }

    &--LanguageSelect {
        margin-left: 1rem;
    }
}
