@import 'styles/includes';

.AccordionInvoiceList {
    $root: &;

    @extend %container;
    margin-top: 3rem;
    margin-bottom: 3rem;

    &__Grid {
        display: grid;
        gap: 2rem;
    }

    &__Title {
        @extend %h3;
        color: $colorBlue;

        &--Unpaid {
            margin: 3rem 0;
        }
    }

    &__Richtext {
        @extend %rich-text;
        margin: 1rem 0 1rem;
        max-width: 54.8rem;
        color: $colorContrast;
    }

    &__Text {
        @extend %p;
        padding: 2.3rem 2rem 2.2rem;
        color: $colorContrast;
        background-color: $colorLichen;
        border: 0.1rem solid $colorBlue;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 3rem;
    }

    &__Button {
        margin: 4rem 0 0;
        text-align: center;
    }

    &__Spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.2rem;
        aspect-ratio: 920 / 270;
        color: $colorBlue;
    }
}
