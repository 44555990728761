@import 'styles/includes';
.CardElectricyContract {
    $root: &;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &__Header {
        margin: 0;
        padding: 2.4rem 2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        color: $colorBlue;
        background-color: $colorLichen;
        border-radius: 0.8rem 0.8rem 0 0;
        border-top: 1px solid $colorBlue;
        border-right: 1px solid $colorBlue;
        border-left: 1px solid $colorBlue;

        @include media(m) {
            padding: 3.2rem 3.2rem 3.2rem 2.4rem;
        }
    }

    &__Title {
        @extend %h4;
        color: $colorBlue;
    }

    &__Content {
        @extend %rich-text;
        padding: 2rem;
        height: 100%;
        color: $colorContrast;
        border-radius: 0 0 0.8rem 0.8rem;
        border-right: 1px solid $colorBlue;
        border-bottom: 1px solid $colorBlue;
        border-left: 1px solid $colorBlue;

        @include media(m) {
            padding: 2.4rem;
        }

        #{$root}--Checked & {
            color: $colorWhite;
            background-color: $colorBlue;
        }
    }

    &__Label {
        font-size: 1.2rem;
        font-family: $fontBold;
        text-transform: uppercase;
        letter-spacing: 0.0496rem;
        line-height: 2.4rem;
        color: $colorBlue;
    }

    &__Radio {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__Input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    &__ControlIndicator {
        margin-right: 1.5rem;
        width: 2.8rem;
        height: 2.8rem;
        display: block;
        border: 2px solid $colorBlue;
        border-radius: 50%;
        background-color: $colorWhite;
        background-size: 1.5rem;
        background-position: center center;
        background-repeat: no-repeat;
        user-select: none;
        bottom: 10px;
        left: 0;
        flex-shrink: 0;
    }

    &__Input:focus ~ #{$root}__ControlIndicator {
        box-shadow:
            0 0 0 0.075rem $colorWhite,
            0 0 0 0.2rem #0074d9;
    }

    &__Input:checked ~ #{$root}__ControlIndicator {
        background-color: $colorBlue;
    }

    &__Input:active ~ #{$root}__ControlIndicator {
        background-color: $colorBlue;
    }

    &__Input:checked ~ #{$root}__ControlIndicator {
        background-image: url('/img/check-white.svg');
    }
}
