@import 'styles/includes';

.Step3 {
    &__Back {
        @extend %step-back;
    }
    &__Container {
        display: flex;
        gap: 2.4rem;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__Content {
        @include media(ml) {
            max-width: 50%;
        }
    }

    &__Logo {
        display: none;

        @include media(m) {
            width: 100%;
            max-width: 152px;
            height: 45px;
            display: block;
            background-image: url('/img/logo-jamtkraft.svg');
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
        }
    }

    &__Card {
        margin: 2.4rem 0 0 0;
        padding: 3.7rem 4rem;
        border-radius: 1.6rem;
        border: 1px solid $colorBlue;
    }

    &__Grid {
        margin: 2rem 0;
        display: grid;
        gap: 2.4rem;

        @include media(m) {
            grid-template-columns: repeat(2, 1fr);
            align-items: start;
        }
    }

    &__Tooltip {
        margin: 2.4rem 0 0 0;
    }
}
