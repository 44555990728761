@import 'styles/includes';

.StreamField {
    &__Component {
        margin: 6rem auto;

        &--HeroFiftyFifty,
        &--CardCtaSmallList,
        &--ContactUs,
        &--MyPagesLoginChoices,
        &--MoveForm,
        &--ProductList,
        &--TabbedContent,
        &--TextColumns,
        &--SignupElectricityForm,
        &--SignupElectricityGridForm,
        &--SignupFork {
            margin: 0 auto;
        }

        &--PropertyCardList {
            margin: 4rem auto;
        }

        &--Space {
            margin-top: 60px;
        }

        &--Article {
            margin: 0 auto 2rem auto;

            &.StreamField__Component--Quote,
            &.StreamField__Component--Form,
            &.StreamField__Component--InlineCard {
                margin: 4rem 0;
            }

            &.StreamField__Component--ProductDiagram,
            &.StreamField__Component--Diagrams,
            &.StreamField__Component--CtaButtonList {
                margin-left: -16px;
                margin-right: -16px;
            }

            &.StreamField__Component--InlineImages {
                margin: 16px 0;

                @include media(m) {
                    margin: 24px 0;
                }
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--Flow {
            margin: 0;

            &.StreamField__Component--RichText,
            &.StreamField__Component--FormPersonalData,
            &.StreamField__Component--FormPayment,
            &.StreamField__Component--Summary {
                margin: 24px 0;

                @include media(m) {
                    max-width: 50%;
                }
            }

            &.StreamField__Component--EstimatedConsumption {
                margin: 32px 0 15px 0;
            }
            &.StreamField__Component--CardPricingList {
                margin: 15px 0 32px 0;
            }
        }
    }
}
