@import 'styles/includes';

.Image {
    &__FadeIn {
        opacity: 0;
        transition: opacity $transitionDelaySlow;

        &--Loaded {
            opacity: 1;
        }
    }

    &--Fit {
        object-fit: cover;
    }
}
