%nav-container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 $spacingM;
    width: 100%;
    max-width: 147rem;
}

%container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 $spacingM;
    width: 100%;
    max-width: 115.2rem;
}

%h1 {
    font-family: $fontBlack;
    font-size: 4.6rem;
    font-weight: 900;
    line-height: 5.6rem;
    letter-spacing: 0.0496rem;
    color: inherit;

    @include media(m) {
        font-size: 6.8rem;
        line-height: 8rem;
        letter-spacing: 0.1504rem;
    }
}

%h2 {
    font-family: $fontBlack;
    font-size: 3.6rem;
    font-weight: 900;
    line-height: 4.6rem;
    letter-spacing: 0.04rem;
    color: inherit;

    @include media(m) {
        font-size: 5.2rem;
        line-height: 6.4rem;
        letter-spacing: 0.08rem;
    }
}

%h3 {
    font-family: $fontBlack;
    font-size: 2.2rem;
    font-weight: 900;
    line-height: 2.8rem;
    letter-spacing: 0.0208rem;
    color: inherit;

    @include media(m) {
        font-size: 3.2rem;
        line-height: 4.2rem;
        letter-spacing: 0.0496rem;
    }
}

%h4 {
    font-family: $fontBlack;
    font-size: 1.8rem;
    font-weight: 900;
    line-height: 2.8rem;
    letter-spacing: 0.0208rem;
    color: inherit;

    @include media(m) {
        font-size: 2rem;
        line-height: 2.6rem;
        letter-spacing: 0.04rem;
    }
}

%h5 {
    font-family: $fontBlack;
    font-size: 1.6rem;
    font-weight: 900;
    line-height: 2.4rem;
    letter-spacing: 0.0208rem;
    color: inherit;
}

%p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    color: inherit;

    @include media(m) {
        font-size: 1.6rem;
        line-height: 2.6rem;
    }
}

%small {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: inherit;
}

%label {
    font-family: $fontBold;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.33;
    letter-spacing: 0.0496rem;
    color: $colorBlue;
}

%curved-border {
    position: relative;
    display: inline-block;
    white-space: pre-wrap;
    z-index: 0;

    &::before {
        content: '';
        width: 88%;
        height: 49%;
        position: absolute;
        bottom: -10%;
        right: 0;
        z-index: -1;
        display: block;
        background-image: url('/img/curved-border.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

%curved-border-h3 {
    &::before {
        content: '';
        width: 103px;
        height: 14px;
        position: absolute;
        top: 17px;
        left: 0;
        z-index: -1;
        display: block;
        background-image: url('/img/curved-border.svg');
        background-size: contain;
        background-repeat: no-repeat;

        @include media(m) {
            width: 152px;
            height: 20px;
            top: 26px;
            left: 3px;
        }
    }
}

%help-text {
    @extend %p;
    margin-top: 0.6rem;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    line-height: 2.2rem;
    color: $colorBlue;
    display: inline-block;
}

%ul {
    li {
        @extend %p;
        padding-left: 18px;
        font-weight: 400;
        color: inherit;
        position: relative;

        @include media(m) {
            padding-left: 23px;
        }

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            background-color: $colorYellow;
            display: inline-block;
            border-radius: 16px;
            position: absolute;
            left: 0;
            top: 8px;

            @include media(m) {
                top: 9px;
            }
        }

        &:empty {
            display: none;
        }
    }
}

%ol {
    counter-reset: item;

    li {
        @extend %p;
        padding-left: 18px;
        font-weight: 400;
        color: inherit;
        position: relative;
        list-style-type: none;
        counter-increment: item;

        @include media(m) {
            padding-left: 23px;
        }

        &:before {
            content: counter(item) '.';
            width: initial;
            height: initial;
            background-color: initial;
            font-family: $fontBold;
            font-weight: 700;
            color: $colorBlue;
            position: absolute;
            top: 1px;
            left: 0;
        }

        &:empty {
            display: none;
        }
    }
}

%li-check {
    @extend %p;
    font-weight: 400;
    color: inherit;

    &::before {
        content: '';
        margin-right: 10px;
        width: 12px;
        height: 10px;
        background-image: url('/img/check.svg');
        background-size: 1.2rem 1rem;
        background-repeat: no-repeat;
        display: inline-block;
    }
}

%blockquote {
    @extend %h3;
    margin-bottom: 1rem;
    position: relative;

    &:before {
        content: '';
        margin-bottom: 1rem;
        width: 7.1rem;
        height: 5.5rem;
        background-image: url('/img/quote.svg');
        background-size: 7.1rem 5.5rem;
        background-repeat: no-repeat;
        display: block;
    }
}

%step-back {
    margin: 0 0 24px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.6rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: $colorBlue;
    display: inline-block;
    position: relative;

    &:before {
        content: '';
        top: 50%;
        margin-right: 10px;
        width: 1.7rem;
        height: 1.4rem;
        background-image: url('/img/arrow.svg');
        background-size: 1.7rem 1.4rem;
        background-repeat: no-repeat;
        transform: rotate(-180deg);
        position: relative;
        display: inline-block;

        @include media(ls) {
            transform: rotate(-180deg) translate(50%, 50%);
            position: absolute;
            left: -2rem;
        }
    }
}

%rich-text {
    * {
        &:not(:last-child, h1, h2, h3, h4, h5, h6, li, div) {
            margin-bottom: 2rem;
        }
    }

    color: inherit;

    b {
        font-family: $fontBold;
        font-weight: 700;
    }

    blockquote {
        @extend %blockquote;
        color: $colorBlue;
    }

    a {
        border-bottom: 1px;
        border-style: solid;
        border-color: inherit;

        &:hover {
            border-color: transparent;
        }

        &[href$='.pdf'] {
            &:after {
                content: '';
                margin-left: 6px;
                width: 14px;
                height: 14px;
                background-image: url('/img/icon-pdf.svg');
                background-position: center center;
                background-repeat: no-repeat;
                display: inline-block;
            }
        }

        &[href$='.doc'],
        &[href$='.docx'] {
            &:after {
                content: '';
                margin-left: 6px;
                width: 14px;
                height: 14px;
                background-image: url('/img/icon-doc.svg');
                background-position: center center;
                background-repeat: no-repeat;
                display: inline-block;
            }
        }

        &[target='_blank'] {
            &:after {
                content: '';
                margin-left: 6px;
                width: 14px;
                height: 14px;
                background-image: url('/img/icon-target-blank.svg');
                background-position: center center;
                background-repeat: no-repeat;
                display: inline-block;
            }
        }
    }

    em {
        @extend %p;
        font-style: italic;
    }

    figcaption {
        margin: 1rem 0 2rem;
        font-size: 1.4rem;
        line-height: 1.71;
        color: $colorContrast;

        @include media(m) {
            margin-bottom: 0;
        }

        a {
            border-bottom: 1px;
            border-style: solid;
            border-color: inherit;

            &:hover {
                border-color: transparent;
            }
        }

        b {
            font-family: $fontBold;
            font-weight: 700;
        }

        i {
            font-style: italic;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 1rem;
        word-break: break-word;
        color: $colorBlue;

        b {
            font-family: $fontBlack;
        }
    }

    h1 {
        @extend %h1;
    }

    h2 {
        @extend %h2;
    }

    h3 {
        @extend %h3;
    }

    h4 {
        @extend %h4;
    }

    h5 {
        @extend %h5;
    }

    i {
        font-style: italic;
    }

    img {
        width: 100%;
    }

    p {
        @extend %p;
    }

    @extend %ul;

    ol {
        @extend %ol;
    }
}

%card-link {
    position: absolute;
    inset: 0;
    z-index: 1;
    cursor: pointer;
}
